import { defineStore, acceptHMRUpdate } from 'pinia';
import { useProductsStore } from './productsStore';
import { useNotificationStore } from './notificationStore';
import waitForProducts from '~/utils/waitForProducts';
import handleFrontendError from '../utils/handleFrontendError';
import type {
	CartItem,
	Customer,
	DiscountTag,
	FormattedCartData,
	PricingTierRule,
	PricingTierWithRules,
	Product,
	ProductInventory,
} from '~/types/app.types';
import type { PricedCartData } from '~/types/api/dutchie.types';

export const useCartStore = defineStore('Cart', () => {
	const ORDER_LIMIT_GRAMS = 70;
	const ORDER_LIMIT_CONCENTRATE_GRAM_MULTIPLIER = 4.66;
	// Location will become dynamic once we have more than one shop location
	const location =
		useRuntimeConfig().public.isStaging || process.env.NODE_ENV === 'development'
			? 'sandbox'
			: 'monroe';

	// ==============================
	// Stores
	// ==============================
	const ProductsStore = useProductsStore();
	const NotificationStore = useNotificationStore();

	// ==============================
	// #region State
	// ==============================
	const loading = ref(true);
	const items = ref<CartItem[]>([]);
	const cartOpen = ref(false);
	const merchCrossSellOpen = ref(false);
	const customerInfo = ref<Customer>({
		firstName: '',
		lastName: '',
		email: '',
		phone: '',
		birthday: '',
	});
	const cartDataFromDutchiePOS = ref<PricedCartData | null>(null);

	const itemsCount = computed(() => items.value.reduce((sum, item) => sum + item.quantity, 0));
	const pricingTierDefinitions = ref<PricingTierWithRules[]>(ProductsStore.pricingTiers);
	const categoriesInCart = computed(() => [
		...new Set(items.value.flatMap((item) => item.categories)),
	]);
	// #endregion
	// ==============================
	// #region Cart Totals
	// ==============================
	const subTotal = computed(() => {
		return items.value.reduce((total, item) => {
			if (item.priceNonDiscounted) {
				/**
				 * For items that apply tiered pricing, the dropdown selection shows
				 * discounted prices for all tiers above the base tier. For instance,
				 * 1/4oz will display as $50, which is a discounted price. To correctly
				 * compute and display the subtotal and total, this property uses the
				 * non-discounted price where applicable. In the above example, 1/4oz
				 * would be represented as $60, not the discounted $50.
				 */
				return total + item.quantity * item.priceNonDiscounted;
			} else {
				return total + item.quantity * (item.price ?? 0);
			}
		}, 0);
	});

	const discounts = computed(() => {
		const cartTotalAfterDiscounts = cartItemsAfterAllDiscounts.value.reduce((acc, cur) => {
			return (acc += cur.price * cur.quantity);
		}, 0);
		return subTotal.value - cartTotalAfterDiscounts;
	});

	const total = computed(() => {
		return subTotal.value - discounts.value;
	});

	const cartTotalsFromDutchiePOSAdjustedForTagBasedDiscounts = computed(() => {
		/**
		 * Adjusts the cart totals returned from Dutchie based on tag-based discounts.
		 *
		 * Dutchie incorporates price tier discounts directly into the POS pricing,
		 * but tag-based discounts are applied post-transaction and are not reflected
		 * in the API cart pricing data returned by Dutchie.
		 *
		 * Although tag-based discounts are automatically applied in Dutchie, these discounts
		 * are not reflected in the POS cart until inventory is assigned to line items. A Dutchie
		 * preorder initially contains 0 items with a total of $0 until shop staff assigns inventory
		 * to the cart. Consequently, Dutchie's API does not include automatic discounts in its
		 * returned data. We need to recalculate the cart totals to account for tag-based discounts.
		 */
		if (!cartDataFromDutchiePOS.value) return null;

		// 01. Calculate the dollar amount of tag-based discounts
		const tagBasedDiscountsDollarAmount = cartItemsAfterAllDiscounts.value.reduce(
			(acc, cur) => {
				if (cur.originalPrice) {
					return acc + (cur.originalPrice - cur.price) * cur.quantity;
				}
				return acc;
			},
			0,
		);

		// 02. Subtract the tag-based discount amount from the original Dutchie subtotal
		const newSubtotal = cartDataFromDutchiePOS.value.subTotal - tagBasedDiscountsDollarAmount;

		// 03. Apply the tax rate to the new subtotal
		const taxRate = cartDataFromDutchiePOS.value.taxes / cartDataFromDutchiePOS.value.subTotal;
		const newTaxes = newSubtotal * taxRate;
		const newTotal = newSubtotal + newTaxes;

		return {
			subTotal: newSubtotal.toFixed(2),
			taxes: newTaxes.toFixed(2),
			total: newTotal.toFixed(2),
		};
	});
	// #endregion
	// ==============================
	// #region Cart Limits
	// ==============================
	const gramsInCart = computed(() => {
		return items.value?.reduce((acc, cur) => {
			if (cur.isConcentrate) {
				return (acc +=
					(cur.grams ?? 0) * ORDER_LIMIT_CONCENTRATE_GRAM_MULTIPLIER * cur.quantity);
			}
			return (acc += (cur.grams ?? 0) * cur.quantity);
		}, 0);
	});

	const gramsToCartLimitPercentage = computed(() => {
		if (gramsInCart.value) {
			return (gramsInCart.value / ORDER_LIMIT_GRAMS) * 100;
		} else {
			return 0;
		}
	});

	const concentrateGramsInCart = computed(() => {
		if (items.value.length) {
			const concentrateGramsInCart = items.value.reduce((grams, item) => {
				if (item.isConcentrate) {
					return grams + (item.grams ?? 0) * item.quantity;
				}
				return grams;
			}, 0);

			return concentrateGramsInCart;
		} else {
			return 0;
		}
	});
	// #endregion
	// ==============================
	// #region Tag-based Discounts
	// Note: Only percentage-based discounts are considered.
	// ==============================
	const tagBasedDiscounts = ref<DiscountTag[]>();

	async function getDiscounts() {
		try {
			const data = await $fetch('/api/dutchie/preorder/discounts');
			tagBasedDiscounts.value = data;
		} catch (error) {
			handleFrontendError(error, false);
		}
	}
	getDiscounts();
	// #endregion
	// ==============================
	// #region Cart Items with Discount Adjustments
	// ==============================
	interface GramsPerPriceTier {
		[priceTierId: number]: number;
	}
	function applicablePriceTiers(cartItems: CartItem[]): GramsPerPriceTier {
		const itemsWithPriceTiers = cartItems.filter((item) => item.pricingTierId);

		const itemsGroupedByPriceTier: Record<string, CartItem[]> = {};
		for (const item of itemsWithPriceTiers) {
			if (item.pricingTierId) {
				if (!itemsGroupedByPriceTier[item.pricingTierId]) {
					itemsGroupedByPriceTier[item.pricingTierId] = [];
				}

				itemsGroupedByPriceTier[item.pricingTierId].push(item);
			}
		}

		const totalGramsPerTier = Object.entries(itemsGroupedByPriceTier).reduce(
			(totalGrams, [tierId, items]) => {
				totalGrams[tierId] = items.reduce(
					(grams, item) => grams + item.quantity * (item.grams ?? 0),
					0,
				);
				return totalGrams;
			},
			{},
		);

		return totalGramsPerTier;
	}

	interface PricePerGramByTier {
		[priceTierId: number]: number;
	}
	function pricePerGram(gramsPerPriceTier: GramsPerPriceTier) {
		const pricePerGramByPriceTierId: PricePerGramByTier = {};

		for (const [key, grams] of Object.entries(gramsPerPriceTier)) {
			const pricingTier = pricingTierDefinitions.value.find((tier) => tier.id === +key);

			if (pricingTier) {
				const sortedRules = [...pricingTier.pricingTierRules].sort(
					(a, b) => a.startWeightGrams - b.startWeightGrams,
				);

				let applicableRule: PricingTierRule | undefined;
				for (const rule of sortedRules) {
					if (grams >= rule.startWeightGrams && grams < rule.endWeightGrams) {
						applicableRule = rule;
						break;
					}
				}

				if (applicableRule) {
					pricePerGramByPriceTierId[key] = applicableRule.pricePerGram;
				}
			}
		}
		return pricePerGramByPriceTierId;
	}

	function quantityPerTag(cartItems: CartItem[]) {
		const tagQuantities = {};
		cartItems.forEach((item) => {
			item.tagIds?.forEach((tagId) => {
				if (!tagQuantities[tagId]) {
					tagQuantities[tagId] = 0;
				}
				tagQuantities[tagId] += item.quantity;
			});
		});

		return tagQuantities;
	}

	const cartItemsAfterAllDiscounts = computed(() => {
		if (!items.value) return [];
		try {
			const cartDeepClone = items.value.map((item) => ({ ...item }));

			const tiers = applicablePriceTiers(cartDeepClone);
			const quantitiesPerTag = quantityPerTag(cartDeepClone);

			const pricesPerGram = pricePerGram(tiers);
			const itemsAfterPriceTierDiscounts = cartDeepClone.map((item) => {
				if (item.pricingTierId && item.grams) {
					item.price = parseFloat(
						Number(item.grams * pricesPerGram[item.pricingTierId]).toFixed(2),
					);
					const priceTierName = pricingTierDefinitions.value.find(
						(tier) => tier.id === item.pricingTierId,
					)?.name;
					item.discountTitle = `Mix & Match: '${priceTierName}'`;
				}
				return item;
			});

			const itemsAfterPriceTierAndTagBasedDiscounts = itemsAfterPriceTierDiscounts.map(
				(item) => {
					const applicableDiscounts = tagBasedDiscounts.value?.filter(
						(discount) =>
							item.tagIds?.includes(discount.tagId) &&
							quantitiesPerTag[discount.tagId] >= discount.discount.minItems,
					);

					if (applicableDiscounts?.length) {
						const bestDiscount = applicableDiscounts.reduce((max, current) => {
							return current.discount.amount > max.discount.amount ? current : max;
						});

						if (item.price) {
							const discountAmount = item.price * bestDiscount.discount.amount;
							item.originalPrice = item.price;
							item.price -= discountAmount;
							item.hasNonStackableDiscount = true;
							item.discountTitle = bestDiscount.discount.name;
						}
					}
					return item;
				},
			);
			return itemsAfterPriceTierAndTagBasedDiscounts;
		} catch (error) {
			handleFrontendError(error, false);
			return [];
		}
	});
	// #endregion
	// ==============================
	// #region Cart Methods
	// ==============================
	function exceedsMaxCartGrams(isConcentrate: boolean, grams: number, quantity = 1) {
		let gramsBeingAddedToCart = 0;
		if (isConcentrate) {
			gramsBeingAddedToCart = grams * ORDER_LIMIT_CONCENTRATE_GRAM_MULTIPLIER * quantity;
		} else {
			gramsBeingAddedToCart = grams * quantity;
		}
		return gramsInCart.value + gramsBeingAddedToCart > ORDER_LIMIT_GRAMS;
	}

	function quantityExceedsAvailableInventory(
		productId: number,
		quantity: number,
		isGroupedProduct = false,
	) {
		let storeSpecificInventory: ProductInventory | undefined;

		if (isGroupedProduct) {
			const groupedProducts = ProductsStore.products.flatMap(
				(product) => product.groupedProductData || [],
			);
			const product = groupedProducts.find(
				(groupedProduct) => groupedProduct.id === productId,
			);

			storeSpecificInventory = product?.inventory?.find(
				(inventory) => inventory.dutchieLocationId === ProductsStore.storeLocationId,
			);
		} else {
			const product = ProductsStore.products.find(
				(product) => product.dutchieId === productId,
			);

			storeSpecificInventory = product?.productInventory.find(
				(location) => location.dutchieLocationId === ProductsStore.storeLocationId,
			);
		}
		const availableInventory = storeSpecificInventory?.quantity;

		if (!availableInventory) {
			throw createError({ message: 'There is no available inventory for this product.' });
		}

		if (quantity <= availableInventory) {
			return false;
		}

		return true;
	}

	function addItemToCart(
		productId: number,
		price: number,
		priceNonDiscounted: number | null,
		size: string,
		quantity: number,
		pricingTierId: number | null,
		productQuantityMultiplier: number | null,
		grams: number | null,
		isConcentrate = false,
		isGroupedProduct = false,
		categories: string[] | null = [],
		tagIds?: number[],
	) {
		try {
			if (grams) {
				if (exceedsMaxCartGrams(isConcentrate, grams, quantity)) {
					NotificationStore.createNotification({
						type: 'error',
						message: 'Adding this item will cause the cart to exceed its allowed limit',
					});
					console.warn('This will cause the cart to exceed its limit');
					return;
				}
			}

			const item = items.value.find((item) => item.id === productId && item.size === size);

			if (quantityExceedsAvailableInventory(productId, quantity, isGroupedProduct)) {
				NotificationStore.createNotification({
					type: 'error',
					message:
						'There is not enough inventory in stock to meet your quantity. Please reduce your item count.',
				});
				return;
			}

			if (item) {
				item.quantity += quantity;
			} else {
				items.value.push({
					id: productId,
					price,
					priceNonDiscounted,
					size,
					quantity,
					pricingTierId,
					productQuantityMultiplier,
					grams,
					isConcentrate,
					isGroupedProduct,
					categories,
					tagIds,
				});
			}
		} catch (error) {
			handleFrontendError(error, false);
		}
	}

	function incrementItemQuantity(productId: number, size: string, isGroupedProduct = false) {
		const item = items.value.find((item) => item.id === productId && item.size === size);

		if (!item) return;

		if (quantityExceedsAvailableInventory(productId, item.quantity + 1, isGroupedProduct)) {
			NotificationStore.createNotification({
				type: 'error',
				message: `Sorry, the quantity you've selected exceeds our current stock for this item.`,
			});
			return;
		}

		if (item.grams) {
			if (exceedsMaxCartGrams(item.isConcentrate, item.grams)) {
				NotificationStore.createNotification({
					type: 'error',
					message: 'Adding this item will cause the cart to exceed its allowed limit',
				});
				console.warn('This will cause the cart to exceed its limit');
				return;
			}
		}

		item.quantity++;
	}

	function decrementItemQuantity(productId: number, size: string) {
		const item = items.value.find((item) => item.id === productId && item.size === size);

		if (!item) return;

		if (item.quantity > 1) {
			item.quantity--;
		} else {
			items.value = items.value.filter(
				(item) => !(item.id === productId && item.size === size),
			);
		}
	}

	function removeItemFromCart(productId: number, size: string) {
		items.value = items.value.filter((item) => !(item.id === productId && item.size === size));
	}

	function clearCart() {
		items.value = [];
		cartOpen.value = false;
	}

	/** Remove items that are no longer available when populating a cart from local storage. */
	function checkCartItemsAvailibility(items: CartItem[]) {
		return items.filter((item) => {
			if (item.isGroupedProduct) {
				// Check grouped products
				return ProductsStore.products.some(
					(product: Product) =>
						product.groupedProductData &&
						product.groupedProductData.some(
							(groupedItem) => groupedItem.id === item.id,
						),
				);
			} else {
				// Check non-grouped products
				return ProductsStore.products.some(
					(product: Product) => product.dutchieId === item.id,
				);
			}
		});
	}
	// #endregion
	// ==============================
	// #region Dutchie POS
	// ==============================
	const formattedCartDataForDutchiePOS = computed(() => {
		/**
		 * Dutchie is designed to recognize products based on their smallest possible size. To accommodate
		 * products that come in larger sizes (products that utilize pricing tiers), we utilize the
		 * 'productQuantityMultiplier'. For instance, if a product is available in a 1/2oz size, the multiplier
		 * will be set to 4, signifying that it is equivalent to four 1/8oz portions.
		 */
		const summedQuantitesById: Record<number, number> = items.value.reduce((sum, item) => {
			if (!sum[item.id]) {
				sum[item.id] = 0;
			}

			if (item.productQuantityMultiplier) {
				sum[item.id] += item.quantity * item.productQuantityMultiplier;
			} else {
				sum[item.id] += item.quantity;
			}

			return sum;
		}, {});

		const formattedData: FormattedCartData[] = [];
		for (const [productId, quantity] of Object.entries(summedQuantitesById)) {
			formattedData.push({
				productId,
				quantity,
			});
		}

		return formattedData;
	});

	async function priceCartFromDutchiePOS() {
		if (!items.value.length) return;
		try {
			const response = await $fetch<PricedCartData>(
				`/api/dutchie/preorder/price-cart?location=${location}`,
				{
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						Accept: 'application/json',
					},
					body: JSON.stringify({
						cart: formattedCartDataForDutchiePOS.value,
						customerTypeId: 2,
					}),
				},
			);

			cartDataFromDutchiePOS.value = response ?? null;
			return response;
		} catch (error) {
			handleFrontendError(error);
		}
	}
	// #endregion
	// ==============================
	// #region Cross-sell Products
	// ==============================
	const crossSellProducts = computed(() => {
		if (categoriesInCart.value.length) {
			const relevantProducts = ProductsStore.products.filter((product: Product) => {
				const isInCart = items.value.some((item) => item.id === product.dutchieId);
				if (isInCart) return false;

				const matchesCategory = product.productMetadata?.productCategories?.some(
					(category) => categoriesInCart.value.includes(category.name),
				);
				const hasClothingCategory = product.productMetadata?.productCategories?.some(
					(category) => category.name === 'Clothing',
				);

				return matchesCategory && !hasClothingCategory;
			});
			// eslint-disable-next-line security-node/detect-insecure-randomness
			return relevantProducts.sort(() => 0.5 - Math.random()).slice(0, 3);
		}
		return [];
	});

	const merchCrossSellProducts = computed<Product[]>(() => {
		const relevantProducts = ProductsStore.products.filter((product: Product) => {
			const hasMerchCategory = product.productMetadata?.productCategories?.some(
				(category) => category.name === 'Merch',
			);
			const doesNotHaveClothingCategory = !product.productMetadata?.productCategories?.some(
				(category) => category.name === 'Clothing',
			);

			return hasMerchCategory && doesNotHaveClothingCategory;
		});
		if (relevantProducts.length >= 3) {
			// eslint-disable-next-line security-node/detect-insecure-randomness
			return relevantProducts.sort(() => 0.5 - Math.random()).slice(0, 3);
		}
		// If there are not enough merch products in stock show a random assortment of all products as a fallback.
		// eslint-disable-next-line security-node/detect-insecure-randomness
		return ProductsStore.products.sort(() => 0.5 - Math.random()).slice(0, 3);
	});
	// #endregion
	// ==============================
	// #region State Persistence
	// ==============================
	async function populateCartFromLocalStorage() {
		const cartInLocalStorage = localStorage.getItem('cart');
		if (cartInLocalStorage) {
			const cartData: CartItem[] = JSON.parse(cartInLocalStorage);

			// Check for the presence of the tagIds property in each object.
			// If this property is missing, the cart is from before tag-based discounting
			// was implemented and needs to be cleared.
			const isDataValid = cartData.every((item) => Object.hasOwn(item, 'tagIds'));
			if (!isDataValid) {
				localStorage.removeItem('cart');
				return;
			}

			await waitForProducts();
			items.value = checkCartItemsAvailibility(cartData);
		}
	}

	function populateCustomerFromLocalStorage() {
		const customerInLocalStorage = localStorage.getItem('customer');
		if (customerInLocalStorage) {
			customerInfo.value = JSON.parse(customerInLocalStorage);
		}
	}

	if (import.meta.client) {
		populateCartFromLocalStorage();
		populateCustomerFromLocalStorage();
	}

	watch(
		() => items,
		(state) => {
			localStorage.setItem('cart', JSON.stringify(state.value));
		},
		{ deep: true },
	);

	watch(
		() => customerInfo,
		(state) => {
			localStorage.setItem('customer', JSON.stringify(state.value));
		},
		{ deep: true },
	);
	// #endregion
	return {
		addItemToCart,
		cartDataFromDutchiePOS,
		cartItemsAfterAllDiscounts,
		cartOpen,
		cartTotalsFromDutchiePOSAdjustedForTagBasedDiscounts,
		clearCart,
		concentrateGramsInCart,
		crossSellProducts,
		customerInfo,
		decrementItemQuantity,
		discounts,
		exceedsMaxCartGrams,
		formattedCartDataForDutchiePOS,
		gramsInCart,
		gramsToCartLimitPercentage,
		incrementItemQuantity,
		items,
		itemsCount,
		loading,
		location,
		merchCrossSellOpen,
		merchCrossSellProducts,
		ORDER_LIMIT_GRAMS,
		populateCartFromLocalStorage,
		priceCartFromDutchiePOS,
		pricingTierDefinitions,
		removeItemFromCart,
		subTotal,
		tagBasedDiscounts,
		total,
	};
});

if (import.meta.hot) {
	import.meta.hot.accept(acceptHMRUpdate(useCartStore, import.meta.hot));
}
